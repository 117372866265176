import React from "react";
import { Link } from "gatsby"
import "./Footer.css";

const Footer = () => {
  return (
    <div className="footerBg">
      <div className="footerContainer ">
      <Link className="footer-container_item" to="/">
          Home
        </Link>
        <Link className="footer-container_item" to="/pricing">
          Pricing
        </Link>
        <Link className="footer-container_item" to="/shop">
          For Shoppers
        </Link>

        <Link className="footer-container_item" to="/about">
          About
        </Link>
        <Link className="footer-container_item" to="/contact-us">
          Contact Us
        </Link>
        <Link className="footer-container_item" to="/merchant-help-center">
          Merchant Help Center
        </Link>
        <Link className="footer-container_item" to="/shopper-help-center">
          Shopper Help Center
        </Link>

        <Link className="footer-container_item" to="/privacy-policy">
          Privacy
        </Link>
        <Link className="footer-container_item" to="/tos">
          Terms{" "}
        </Link>
       
      </div>
    </div>
  );
};

export default Footer;
